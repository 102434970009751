import React, { useState } from "react";
import "./Projects.css";
import Masonry from "react-responsive-masonry";

function importAll(r) {
   return r.keys().map(r);
}

const Projects = () => {
   // const images = [
   //   {
   //     img: one,
   //     style: { width: "100%", height: "23%", display: "block" },
   //   },
   //   {
   //     img: two,
   //     style: { width: "100%", height: "20%", display: "block" },
   //   },
   //   {
   //     img: three,
   //     style: { width: "100%", height: "95%", display: "block" },
   //   },
   //   {
   //     img: four,
   //     style: { width: "100%", height: "80%", display: "block" },
   //   },
   //   {
   //     img: five,
   //     style: { width: "100%", height: "20%", display: "block" },
   //   },
   //   {
   //     img: six,
   //     style: { width: "100%", height: "20%", display: "block" },
   //   },
   //   {
   //     img: seven,
   //     style: { width: "100%", height: "15%", display: "block" },
   //   },
   //   {
   //     img: eight,
   //     style: { width: "100%", height: "100%", display: "block" },
   //   },
   //   {
   //     img: nine,
   //     style: { width: "100%", height: "60%", display: "block" },
   //   },
   //   {
   //     img: ten,
   //     style: { width: "100%", height: "50%", display: "block" },
   //   },
   //   {
   //     img: eleven,
   //     style: { width: "100%", height: "30%", display: "block" },
   //   },
   //   {
   //     img: twelve,
   //     style: { width: "100%", height: "20%", display: "block" },
   //   },
   // ];
   const images = importAll(
      require.context(
         "../../Images/Projects",
         false,
         /\.(png|jpe?g|svg)$/
      )
   );

   const [showImg, setShowImg] = useState(false);
   const [viewedImg, setViewedImg] = useState();
   const viewImg = (image) => {
      setViewedImg(image);
   };

   return (
      <div className="Projects" id="projects">
         <h2>Projects</h2>
         <div className="collage" style={showImg ? { opacity: "20%" } : null}>
            <Masonry columnsCount={3} gutter="1em">
               {images.map((image, i) => (
                  <img
                     key={i}
                     src={image}
                     style={image.style}
                     onClick={() => {
                        setShowImg(true);
                        viewImg(image);
                     }}
                  />
               ))}
            </Masonry>
         </div>
         <div
            className="viewImg"
            style={showImg ? { display: "block" } : { display: "none" }}
         >
            <div className="photo">
               <img src={viewedImg} alt="" />
            </div>
            <button onClick={() => setShowImg(false)}>
               <p>close</p>
            </button>
         </div>
      </div>
   );
};

export default Projects;
