import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Yukotouch Builders (PTY) LTD </p>
      <p>198 Columbine AveMondeor, Johannesburg South, 2091</p>
    </div>
  );
};

export default Footer;